import React from 'react';
import './Anonymously.css';
import { Link } from 'react-router-dom';

function Anonymously() {
  return (
    <section className="anonymously-section">
<div className="container">
<div className="anonymously__content">
<div className="info">
<h2 className="title-section" data-i18n="payment">Pay <span className='dark-blue'>anonymously</span> with cryptocurrency</h2>
<p data-i18n="payment_desc">Or choose any of our other trusted payment providers.</p>
<a href="https://www.verismsfy.com/register" className="btn" data-i18n="register_now">Register Now</a>
</div>
<img src="images/bitcoin.svg" alt="Cheap SMS Verifications, non-VoIP phone numbers - Verismsfy" className="img" loading="lazy" width="550" height="395.5"/>
</div>
<h3 className="title-section" data-i18n="pay_with_fiat">Or pay with <span className="dark-blue">PayPal</span> </h3>
<p className="color" data-i18n="pay_with_fiat_desc">Pay with your own currency like the US Dollar and the Euro.</p>
<a href="https://www.verismsfy.com/register" className="btn" data-i18n="register_now">Register Now</a>
</div>
</section>
  )
}

export default Anonymously