import React from 'react';
import './OrderSection.css';
// import { Button } from './Button';

function OrderSection() {
  return (
    <section className="order-section">
                    <div className="container">
                        <h2 className="title-section" data-i18n="motto">
                            <span className="dark-blue">Verify </span>
                            with a Text, <span className="dark-blue">Protect </span>
                            with Confidence.

                        </h2>
                        <div className="d-flex">
<div className="order-items">
<div className="swiper swiper-initialized swiper-vertical swiper-autoheight swiper-backface-hidden">
<div className="swiper-wrapper" id="most_purchased" style={{height: '138px', transform: 'translate3d(0px, 0px, 0px)'}}>

									<div className="swiper-slide " style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Facebook</b></p>
												</div>
												<div>
													<p>9225 orders</p>
												</div>
												<div><strong>$0.15</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide " style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Telegram</b></p>
												</div>
												<div>
													<p>7296 orders</p>
												</div>
												<div><strong>$0.26</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>WhatsApp</b></p>
												</div>
												<div>
													<p>5673 orders</p>
												</div>
												<div><strong>$0.31</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Snapchat</b></p>
												</div>
												<div>
													<p>5441 orders</p>
												</div>
												<div><strong>$0.03</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Yahoo</b></p>
												</div>
												<div>
													<p>3549 orders</p>
												</div>
												<div><strong>$0.14</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Not Listed</b></p>
												</div>
												<div>
													<p>3375 orders</p>
												</div>
												<div><strong>$0.36</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Amazon</b></p>
												</div>
												<div>
													<p>3188 orders</p>
												</div>
												<div><strong>$0.31</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>Google</b></p>
												</div>
												<div>
													<p>2655 orders</p>
												</div>
												<div><strong>$0.35</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div>
									<div className="swiper-slide" style={{height: '137.5px'}}>
										<div className="item">
											<div className="slide__item-row">
												<div className="country">
													<img src="https://www.smspool.net/assets/img/lang/us.svg" alt="United States sms verifications" loading="lazy" width="17.49" height="17.49"/>
													<span>United States</span>
												</div>
												<div className="app">
													<p><b>TikTok</b></p>
												</div>
												<div>
													<p>2342 orders</p>
												</div>
												<div><strong>$0.29</strong></div>
											</div>
											<a href="https://www.verismsfy.com/login" className="btn">Order Now</a>
										</div>
									</div></div>
</div>
{/* <div className="swiper-button-next"></div> */}
</div>
<div className="order-advantages">
<div className="advantages-item">
<div>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 30 35" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M3.80704 2.66493C3.50413 2.66493 3.21363 2.78526 2.99944 2.99944C2.78526 3.21363 2.66493 3.50413 2.66493 3.80704V31.0274C2.66493 31.3303 2.78526 31.6208 2.99944 31.835C3.21363 32.0491 3.50413 32.1695 3.80704 32.1695H26.0782C26.3811 32.1695 26.6716 32.0491 26.8858 31.835C27.1 31.6208 27.2203 31.3303 27.2203 31.0274V10.5454L19.3398 2.66493H3.80704ZM1.11506 1.11506C1.82901 0.401097 2.79735 0 3.80704 0H19.8918C20.2452 0 20.5841 0.140384 20.834 0.39027L29.495 9.05128C29.7449 9.30117 29.8852 9.64008 29.8852 9.99347V31.0274C29.8852 32.037 29.4842 33.0054 28.7702 33.7193C28.0562 34.4333 27.0879 34.8344 26.0782 34.8344H3.80704C2.79735 34.8344 1.82902 34.4333 1.11506 33.7193C0.401096 33.0054 0 32.037 0 31.0274V3.80704C0 2.79735 0.401097 1.82901 1.11506 1.11506Z" fill="#4ed877"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M9.05043 18.9496C9.57078 18.4292 10.4145 18.4292 10.9348 18.9496L14.9418 22.9566L18.9487 18.9496C19.4691 18.4292 20.3127 18.4292 20.8331 18.9496C21.3535 19.47 21.3535 20.3136 20.8331 20.834L15.884 25.7831C15.6341 26.033 15.2952 26.1734 14.9418 26.1734C14.5884 26.1734 14.2495 26.033 13.9996 25.7831L9.05043 20.834C8.53007 20.3136 8.53007 19.47 9.05043 18.9496Z" fill="#4ed877"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M14.9418 9.89832C15.6777 9.89832 16.2743 10.4949 16.2743 11.2308V24.8409C16.2743 25.5768 15.6777 26.1734 14.9418 26.1734C14.2059 26.1734 13.6094 25.5768 13.6094 24.8409V11.2308C13.6094 10.4949 14.2059 9.89832 14.9418 9.89832Z" fill="#4ed877"></path>
</svg>
</div>
<div>
<h2 data-i18n="receive_sms_hassle_free">Receive SMS hassle-free</h2>
<p data-i18n="receive_sms_hassle_free_desc">We currently support a large variety of services including, but not limited to Steam, Tinder, Google, Uber, Discord, and even Twitter. To buy an online phone number has never been this easy!</p>
</div>
</div>
<div className="advantages-item">
<div>
<svg xmlns="http://www.w3.org/2000/svg" width="31" height="25" viewBox="0 0 31 25" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M15.5 0C15.8869 0 16.2495 0.188791 16.4714 0.505785L23.3686 10.3588L28.9757 4.75168C29.3149 4.41255 29.8249 4.3111 30.268 4.49463C30.7111 4.67817 31 5.11056 31 5.59016V19.9044C31 21.0949 30.527 22.2368 29.6852 23.0786C28.8433 23.9205 27.7015 24.3934 26.5109 24.3934H4.48907C3.29849 24.3934 2.15668 23.9205 1.31482 23.0786C0.472954 22.2368 0 21.0949 0 19.9044V5.59016C0 5.11056 0.288909 4.67817 0.732009 4.49463C1.17511 4.3111 1.68514 4.41255 2.02427 4.75168L7.63143 10.3588L14.5286 0.505785C14.7505 0.188791 15.1131 0 15.5 0ZM15.5 3.25357L8.76379 12.8767C8.56277 13.1639 8.24499 13.3474 7.89578 13.378C7.54658 13.4086 7.20174 13.2831 6.95387 13.0352L2.37158 8.45292V19.9044C2.37158 20.466 2.59468 21.0046 2.99178 21.4017C3.38889 21.7988 3.92748 22.0219 4.48907 22.0219H26.5109C27.0725 22.0219 27.6111 21.7988 28.0082 21.4017C28.4053 21.0046 28.6284 20.466 28.6284 19.9044V8.45292L24.0461 13.0352C23.7983 13.2831 23.4534 13.4086 23.1042 13.378C22.755 13.3474 22.4372 13.1639 22.2362 12.8767L15.5 3.25357Z" fill="#4ed877"></path>
</svg>
</div>
<div>
<h2 data-i18n="high_quality_sms_verifications">High quality SMS verifications</h2>
<p data-i18n="high_quality_sms_verifications_desc">At verismsfy, we pride ourselves on providing the highest quality SMS verifications for your SMS verification needs. We make sure to only provide non-VoIP phone numbers in order to work with any service.</p>
</div>
</div>
<div className="advantages-item">
<div>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M1.14754 6.10352e-05C1.7813 6.10352e-05 2.29507 0.513831 2.29507 1.1476V27.7049H28.8524C29.4861 27.7049 29.9999 28.2187 29.9999 28.8524C29.9999 29.4862 29.4861 30 28.8524 30H1.14754C0.51377 30 0 29.4862 0 28.8524V1.1476C0 0.513831 0.51377 6.10352e-05 1.14754 6.10352e-05Z" fill="#4ed877"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M28.8525 29.9999C28.2187 29.9999 27.7049 29.4862 27.7049 28.8524L27.7049 2.29511L1.14763 2.2951C0.513865 2.2951 9.40197e-05 1.78133 9.40751e-05 1.14757C9.41305e-05 0.513799 0.513865 2.79398e-05 1.14763 2.79952e-05L28.8525 3.04173e-05C29.4862 3.04727e-05 30 0.513802 30 1.14757L30 28.8524C30 29.4862 29.4862 29.9999 28.8525 29.9999Z" fill="#4ed877"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M17.7931 7.01881C18.0987 6.96278 18.4141 7.03313 18.6669 7.21375L26.1259 12.5416C26.6417 12.91 26.7611 13.6267 26.3927 14.1424C26.0244 14.6581 25.3077 14.7776 24.792 14.4092L18.2974 9.77019L10.4302 21.5709C10.2395 21.857 9.93059 22.0425 9.58847 22.0763C9.24634 22.1102 8.90706 21.9889 8.66396 21.7458L3.33611 16.418C2.88796 15.9698 2.88796 15.2432 3.33611 14.7951C3.78425 14.347 4.51083 14.347 4.95897 14.7951L9.29703 19.1332L17.0451 7.511C17.2175 7.25243 17.4874 7.07483 17.7931 7.01881Z" fill="#4ed877"></path>
</svg>
</div>
<div>
<h2 data-i18n="no_price_fluctuations">No Price Fluctuation</h2>
<p data-i18n="no_price_fluctuations_desc">Our numbers start at 2 cents each, and our prices never fluctuate, even during high demand!</p>
</div>
</div>
</div>
</div>
                    </div>
                </section>
  )
}

export default OrderSection