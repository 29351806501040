import React from 'react';
import './ServiceSection.css';

function ServiceSection() {
  return (
    <section className="service-section" id="mobile_downloads">
<div className="container">
<h2 className="title-section" data-i18n="anytime_anywhere">Use our service anytime, anywhere</h2>
<p data-i18n="anytime_anywhere_desc">Need an SMS verification on the go? We offer you the best solution to use our service anywhere, anytime.</p>
<div className="d-flex">
<div className="d-flex-img">
<img src="/images/photo1_framed.png" alt="Cheap SMS Verifications, non-VoIP phone numbers - Verismsfy" loading="lazy" width="199" height="377"/>
<img src="/images/phone1_framed.png" alt="Cheap SMS Verifications, non-VoIP phone numbers - Verismsfy" loading="lazy" width="199" height="377"/>
</div>

</div>

</div>
</section>
  )
}

export default ServiceSection
