import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './NavBar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>verismsfy
          <img src="/images/logoo.png" alt="logo" loading="lazy" width="100" height="100"/>  
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
           
            <li className='nav-item'>
            <Link
                to='https://www.verismsfy.com/login'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Log in
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='https://www.verismsfy.com/register'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
          </ul>

          <div className='nav-buttons'>
            {button && <Button buttonStyle='btn--outline' linkTo={'https://www.verismsfy.com/register'}>SIGN UP</Button>}
            {button && <Button linkTo={'https://www.verismsfy.com/login'}>LOG IN</Button>}
        </div>
        
         
        </div>
      </nav>
    </>
  );
}

export default Navbar;
