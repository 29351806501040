import React, { useEffect } from 'react';
import './CountrySection.css';

function CountrySection() {
  useEffect(() => {
    const setBackgroundImages = () => {
      const elements = document.querySelectorAll('.top-line, .bottom-line');
      elements.forEach((el) => {
        const imgUrl = el.getAttribute('data-bg');
        el.style.background = `url(${imgUrl})`;
      });
    };

    
    setBackgroundImages();

    
    return () => {
     
    };
  }, []); 

  return (
    <section className="country-section">
      <div className="container">
        <div className="content">
          <div className="data-service">
            <div className="items-service top-line" data-bg="/images/icons/top-line.svg"></div>
            <div className="items-service bottom-line" data-bg="/images/icons/bottom-line.svg"></div>
          </div>
          <div>
            <h2>50+ <span data-i18n="countries">countries</span></h2>
            <h3>300+ <span data-i18n="services">services</span></h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountrySection;
