import React from 'react';
import './ReviewsSection.css';

function ReviewsSection() {
  return (
    <section className="reviews-section" id="reviews">
<div className="container">
<div className="d-flex">
<div className="reviews-info">
<h2 className="title-section" data-i18n="reviews">Reviews</h2>
<p className='reviews-p' data-i18n="reviews_desc">Our reviews section is a reflection of the trust and satisfaction our clients have in Verismsfy
  's services. Read their personal stories and experiences to understand the real-world impact of our solutions. These testimonials showcase our dedication to delivering excellence and building lasting relationships with our customers.</p>
</div>
<div className="review-box">
<div className="first-review">
<img src="/images/icons/google.svg" alt="Cheap SMS Verifications, non-VoIP phone numbers - Verismsfy
" loading="lazy" width="33" height="32"/>
<p>It's all about quality and one things for sure Verismsfy
   got quality and stock!</p>
</div>
<div className="img-box">
<img src="/images/review.svg" alt="Cheap SMS Verifications, non-VoIP phone numbers - Verismsfy
" loading="lazy" width="506" height="337"/>
</div>
<div className="second-review">
<p>vouch! it took me a bit before an actual number worked for me but it did! thanks to the devs!</p>
<svg xmlns="http://www.w3.org/2000/svg" width="75" height="15" viewBox="0 0 75 15" fill="none">
<path d="M6.54894 2.92705C6.8483 2.00574 8.1517 2.00574 8.45106 2.92705L8.95934 4.49139C9.09321 4.90341 9.47717 5.18237 9.9104 5.18237H11.5552C12.524 5.18237 12.9267 6.42199 12.143 6.99139L10.8123 7.9582C10.4618 8.21285 10.3152 8.66422 10.449 9.07624L10.9573 10.6406C11.2567 11.5619 10.2022 12.328 9.41849 11.7586L8.08779 10.7918C7.7373 10.5372 7.2627 10.5372 6.91221 10.7918L5.58151 11.7586C4.7978 12.328 3.74331 11.5619 4.04267 10.6406L4.55095 9.07624C4.68483 8.66422 4.53817 8.21285 4.18768 7.9582L2.85698 6.99139C2.07326 6.42199 2.47604 5.18237 3.44476 5.18237H5.0896C5.52283 5.18237 5.90678 4.90341 6.04066 4.49139L6.54894 2.92705Z" fill="#FFD90F"></path>
<path d="M21.5489 2.92705C21.8483 2.00574 23.1517 2.00574 23.4511 2.92705L23.9593 4.49139C24.0932 4.90341 24.4772 5.18237 24.9104 5.18237H26.5552C27.524 5.18237 27.9267 6.42199 27.143 6.99139L25.8123 7.9582C25.4618 8.21285 25.3152 8.66422 25.449 9.07624L25.9573 10.6406C26.2567 11.5619 25.2022 12.328 24.4185 11.7586L23.0878 10.7918C22.7373 10.5372 22.2627 10.5372 21.9122 10.7918L20.5815 11.7586C19.7978 12.328 18.7433 11.5619 19.0427 10.6406L19.551 9.07624C19.6848 8.66422 19.5382 8.21285 19.1877 7.9582L17.857 6.99139C17.0733 6.42199 17.476 5.18237 18.4448 5.18237H20.0896C20.5228 5.18237 20.9068 4.90341 21.0407 4.49139L21.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M36.5489 2.92705C36.8483 2.00574 38.1517 2.00574 38.4511 2.92705L38.9593 4.49139C39.0932 4.90341 39.4772 5.18237 39.9104 5.18237H41.5552C42.524 5.18237 42.9267 6.42199 42.143 6.99139L40.8123 7.9582C40.4618 8.21285 40.3152 8.66422 40.449 9.07624L40.9573 10.6406C41.2567 11.5619 40.2022 12.328 39.4185 11.7586L38.0878 10.7918C37.7373 10.5372 37.2627 10.5372 36.9122 10.7918L35.5815 11.7586C34.7978 12.328 33.7433 11.5619 34.0427 10.6406L34.551 9.07624C34.6848 8.66422 34.5382 8.21285 34.1877 7.9582L32.857 6.99139C32.0733 6.42199 32.476 5.18237 33.4448 5.18237H35.0896C35.5228 5.18237 35.9068 4.90341 36.0407 4.49139L36.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M51.5489 2.92705C51.8483 2.00574 53.1517 2.00574 53.4511 2.92705L53.9593 4.49139C54.0932 4.90341 54.4772 5.18237 54.9104 5.18237H56.5552C57.524 5.18237 57.9267 6.42199 57.143 6.99139L55.8123 7.9582C55.4618 8.21285 55.3152 8.66422 55.449 9.07624L55.9573 10.6406C56.2567 11.5619 55.2022 12.328 54.4185 11.7586L53.0878 10.7918C52.7373 10.5372 52.2627 10.5372 51.9122 10.7918L50.5815 11.7586C49.7978 12.328 48.7433 11.5619 49.0427 10.6406L49.551 9.07624C49.6848 8.66422 49.5382 8.21285 49.1877 7.9582L47.857 6.99139C47.0733 6.42199 47.476 5.18237 48.4448 5.18237H50.0896C50.5228 5.18237 50.9068 4.90341 51.0407 4.49139L51.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M66.5489 2.92705C66.8483 2.00574 68.1517 2.00574 68.4511 2.92705L68.9593 4.49139C69.0932 4.90341 69.4772 5.18237 69.9104 5.18237H71.5552C72.524 5.18237 72.9267 6.42199 72.143 6.99139L70.8123 7.9582C70.4618 8.21285 70.3152 8.66422 70.449 9.07624L70.9573 10.6406C71.2567 11.5619 70.2022 12.328 69.4185 11.7586L68.0878 10.7918C67.7373 10.5372 67.2627 10.5372 66.9122 10.7918L65.5815 11.7586C64.7978 12.328 63.7433 11.5619 64.0427 10.6406L64.551 9.07624C64.6848 8.66422 64.5382 8.21285 64.1877 7.9582L62.857 6.99139C62.0733 6.42199 62.476 5.18237 63.4448 5.18237H65.0896C65.5228 5.18237 65.9068 4.90341 66.0407 4.49139L66.5489 2.92705Z" fill="#FFD90F"></path>
</svg>
</div>
<div className="last-review">
<p>Works perfectly and quickly. Thanks!</p>
<svg xmlns="http://www.w3.org/2000/svg" width="75" height="15" viewBox="0 0 75 15" fill="none">
<path d="M6.54894 2.92705C6.8483 2.00574 8.1517 2.00574 8.45106 2.92705L8.95934 4.49139C9.09321 4.90341 9.47717 5.18237 9.9104 5.18237H11.5552C12.524 5.18237 12.9267 6.42199 12.143 6.99139L10.8123 7.9582C10.4618 8.21285 10.3152 8.66422 10.449 9.07624L10.9573 10.6406C11.2567 11.5619 10.2022 12.328 9.41849 11.7586L8.08779 10.7918C7.7373 10.5372 7.2627 10.5372 6.91221 10.7918L5.58151 11.7586C4.7978 12.328 3.74331 11.5619 4.04267 10.6406L4.55095 9.07624C4.68483 8.66422 4.53817 8.21285 4.18768 7.9582L2.85698 6.99139C2.07326 6.42199 2.47604 5.18237 3.44476 5.18237H5.0896C5.52283 5.18237 5.90678 4.90341 6.04066 4.49139L6.54894 2.92705Z" fill="#FFD90F"></path>
<path d="M21.5489 2.92705C21.8483 2.00574 23.1517 2.00574 23.4511 2.92705L23.9593 4.49139C24.0932 4.90341 24.4772 5.18237 24.9104 5.18237H26.5552C27.524 5.18237 27.9267 6.42199 27.143 6.99139L25.8123 7.9582C25.4618 8.21285 25.3152 8.66422 25.449 9.07624L25.9573 10.6406C26.2567 11.5619 25.2022 12.328 24.4185 11.7586L23.0878 10.7918C22.7373 10.5372 22.2627 10.5372 21.9122 10.7918L20.5815 11.7586C19.7978 12.328 18.7433 11.5619 19.0427 10.6406L19.551 9.07624C19.6848 8.66422 19.5382 8.21285 19.1877 7.9582L17.857 6.99139C17.0733 6.42199 17.476 5.18237 18.4448 5.18237H20.0896C20.5228 5.18237 20.9068 4.90341 21.0407 4.49139L21.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M36.5489 2.92705C36.8483 2.00574 38.1517 2.00574 38.4511 2.92705L38.9593 4.49139C39.0932 4.90341 39.4772 5.18237 39.9104 5.18237H41.5552C42.524 5.18237 42.9267 6.42199 42.143 6.99139L40.8123 7.9582C40.4618 8.21285 40.3152 8.66422 40.449 9.07624L40.9573 10.6406C41.2567 11.5619 40.2022 12.328 39.4185 11.7586L38.0878 10.7918C37.7373 10.5372 37.2627 10.5372 36.9122 10.7918L35.5815 11.7586C34.7978 12.328 33.7433 11.5619 34.0427 10.6406L34.551 9.07624C34.6848 8.66422 34.5382 8.21285 34.1877 7.9582L32.857 6.99139C32.0733 6.42199 32.476 5.18237 33.4448 5.18237H35.0896C35.5228 5.18237 35.9068 4.90341 36.0407 4.49139L36.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M51.5489 2.92705C51.8483 2.00574 53.1517 2.00574 53.4511 2.92705L53.9593 4.49139C54.0932 4.90341 54.4772 5.18237 54.9104 5.18237H56.5552C57.524 5.18237 57.9267 6.42199 57.143 6.99139L55.8123 7.9582C55.4618 8.21285 55.3152 8.66422 55.449 9.07624L55.9573 10.6406C56.2567 11.5619 55.2022 12.328 54.4185 11.7586L53.0878 10.7918C52.7373 10.5372 52.2627 10.5372 51.9122 10.7918L50.5815 11.7586C49.7978 12.328 48.7433 11.5619 49.0427 10.6406L49.551 9.07624C49.6848 8.66422 49.5382 8.21285 49.1877 7.9582L47.857 6.99139C47.0733 6.42199 47.476 5.18237 48.4448 5.18237H50.0896C50.5228 5.18237 50.9068 4.90341 51.0407 4.49139L51.5489 2.92705Z" fill="#FFD90F"></path>
<path d="M66.5489 2.92705C66.8483 2.00574 68.1517 2.00574 68.4511 2.92705L68.9593 4.49139C69.0932 4.90341 69.4772 5.18237 69.9104 5.18237H71.5552C72.524 5.18237 72.9267 6.42199 72.143 6.99139L70.8123 7.9582C70.4618 8.21285 70.3152 8.66422 70.449 9.07624L70.9573 10.6406C71.2567 11.5619 70.2022 12.328 69.4185 11.7586L68.0878 10.7918C67.7373 10.5372 67.2627 10.5372 66.9122 10.7918L65.5815 11.7586C64.7978 12.328 63.7433 11.5619 64.0427 10.6406L64.551 9.07624C64.6848 8.66422 64.5382 8.21285 64.1877 7.9582L62.857 6.99139C62.0733 6.42199 62.476 5.18237 63.4448 5.18237H65.0896C65.5228 5.18237 65.9068 4.90341 66.0407 4.49139L66.5489 2.92705Z" fill="#FFD90F"></path>
</svg>
</div>
</div>
</div>
</div>
<div className="reviews-grid">
<div className="container">
<h2 className="title-section" data-i18n="why_choose_Verismsfy
">Why choose Verismsfy
  ?</h2>
<div className="d-grid">
<div className="review__item star-rating">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Smooth process</span>
<p>Had an issue of not being able to buy one of the number services and the owner solved the issue within 30 seconds of me telling him. 10/10 site, only one I use for sms verifications.</p>
<strong>Shababan</strong>
</div>
<div className="review__item">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Cheap and reliable service</span>
<p>Cheap and reliable, easy to navigate and auto refund for unused numbers. I haven't tried many others but that's because I found this first and always liked it.</p>
<strong>Randy</strong>
</div>
<div className="review__item">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Best SMS provider</span>
<p>Best SMS provider by far, great prices, good UI, great uptime, great selection, good payment selection, just love this site. I recommend this site to everyone</p>
<strong>Fareto</strong>
</div>
<div className="review__item">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Most reliable SMS verifications</span>
<p>Gonna be honest, i have used a lot of sms providers the last few years. And Verismsfy
   probably the best one out there, fast support, high uptime and extremely lowkey.</p>
<strong>Duck</strong>
</div>
<div className="review__item">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Convenient SMS verifications</span>
<p>Very convenient and guaranteed refund instantly in your credits if the number doesn’t work which the service I used before didn’t do</p>
<strong>Ziva</strong>
</div>
<div className="review__item">
<div>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
<svg width="29" height="29" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18.469 22.5a.75.75 0 0 1-.44-.14L12 17.99l-6.029 4.37a.75.75 0 0 1-1.15-.847l2.35-6.965-6.093-4.178A.75.75 0 0 1 1.5 9h7.518l2.268-6.981a.75.75 0 0 1 1.427 0l2.27 6.984H22.5a.75.75 0 0 1 .424 1.369l-6.096 4.176 2.35 6.963a.75.75 0 0 1-.71.99Z"></path>
</svg>
</div>
<span>Best SMS Service without a doubt!</span>
<p>Their user-friendly service has truly been a game-changer for my business. From day one, I was impressed by how easy it was to navigate their platform.</p>
<strong>Pablo Romero</strong>
</div>
</div>
</div>
</div>
</section>
  )
}

export default ReviewsSection