import React, { useEffect } from 'react';

import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

import { Link } from 'react-router-dom';



function HeroSection() {

    useEffect(() => {
        const scrollers = document.querySelectorAll(".scroller");
    
        
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
          addAnimation();
        }
    
        function addAnimation() {
          scrollers.forEach((scroller) => {
            
            scroller.setAttribute("data-animated", true);
    
            
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);
    
           
            scrollerContent.forEach((item) => {
              const duplicatedItem = item.cloneNode(true);
              duplicatedItem.setAttribute("aria-hidden", true);
              scrollerInner.appendChild(duplicatedItem);
            });
          });
        }
      }, []);




  return (
    <section className="hero">
        <div className="container">
        <div className="d-flex">
                            <div className="hero__info">
                                <h1 data-i18n="hero">Cheapest and Fastest
Online SMS verification
</h1>
                               
                                <p data-i18n="hero_desc">Don't feel comfortable giving out your phone number? Protect your online identity by using our one-time-use non-VoIP phone numbers.
</p>
                                <div className="hero-flex">
                                    <Button  buttonStyle='btn--outline' linkTo={'https://www.verismsfy.com/register'}>Register Now</Button>
                                    <Button  buttonStyle='btn--outline' linkTo={'https://www.verismsfy.com/login'}>More Info</Button>
                                   
                                </div>
                            </div>

                            <div className="hero__img-desktop">
                                <img src='/images/laptop1.png' width="900" height="600"></img>
                            </div>

                            </div>

                        
                        
                        <div className="brands">
    <p style={{ paddingBottom: '10px' }} data-i18n="total_available">
        Over a <b>thousand</b> services available for SMS verification through our system
    </p>
        <div className="d-grid scroller" data-direction="left" data-speed="fast">
        <div className="scroller__inner">
            <img src="/images/brands/twitch.svg" alt="twitch" loading="lazy" width="99" height="32.81"/>
            <img src="/images/brands/reddit.svg" alt="reddit" loading="lazy" width="113" height="36"/>
            <img src="/images/brands/google.svg" alt="google" loading="lazy" width="119" height="39"/>
            <img src="/images/brands/facebook.svg" alt="facebook" loading="lazy" width="129" height="25"/>
            <img src="/images/brands/whatsApp.svg" alt="whatsApp" loading="lazy" width="128" height="37.86"/>
            <img src="/images/brands/linkedin.svg" alt="linkedin" loading="lazy" width="118" height="32"/>
            </div>
        </div>
        <p data-i18n="brand_disclaimer" style={{ paddingTop: '10px' }}>We are not affiliated with the brands listed</p>
        </div>




        </div>
     </section>
  )
}

export default HeroSection
