import React from 'react';
import '../../App.css';

import HeroSection from '../HeroSection';
import Footer from '../Footer';
import OrderSection from '../OrderSection';
// import StepsSection from '../StepsSection';
import ServiceSection from '../ServiceSection';
import CountrySection from '../CountrySection';
import FeaturesSection from '../FeaturesSection';
import ReviewsSection from '../ReviewsSection';
import Anonymously from '../Anonymously';

function Home() {
  return (
    <>
    <main className='page'>
      
      <HeroSection />
      <OrderSection/>
      {/* <StepsSection/> */}
      <ServiceSection/>
      <CountrySection/>
      <FeaturesSection/>
      <ReviewsSection/>
      <Anonymously/>

     
      </main>
      <Footer />
    
    </>
  );
}

export default Home;